import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import * as Styles from "../styles/effect.module.css";
import Cta from "../components/cta";

const DocIndex = ({ data }) => {
  const AccountInformationDoc = data.AccountInformationDoc.nodes;
  const FleetDoc = data.FleetDoc.nodes;
  const PoliciesDoc = data.PoliciesDoc.nodes;
  const SafetyDoc = data.SafetyDoc.nodes;
  const FundamentalDoc = data.FundamentalDoc.nodes;
  const TrackingDoc = data.TrackingDoc.nodes;
  const ReportDoc = data.ReportDoc.nodes;
  const TipsDoc = data.TipsDoc.nodes;

  return (
    <Layout>
      <Seo title="All Docs" />

      <div className="relative py-24 md:py-36 bg-bluebg">
        <div className="relative z-10 max-w-6xl px-8 mx-auto md:px-0 ">
          <h3 className="pt-24 md:pt-12 text-lg md:text-2xl text-bluegray">
            What can we help you with?
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            Documentation
          </h1>
        </div>
        <div className="absolute bottom-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh]"
            }
          ></div>
        </div>
      </div>

      <div className="bg-[hsla(204,26%,93%,1)] pt-12 pb-48">
        <div className="max-w-5xl px-8 mx-auto md:px-0">
          <div className="grid grid-cols-2 gap-6">
            <div className="col-span-2 md:col-span-1">
              <h5 className="mb-4 text-2xl font-medium">Account Information</h5>
              {AccountInformationDoc.map((post) => {
                return (
                  <Link
                    to={post.uri}
                    className="block my-2 text-primary hover:text-primary-light"
                  >
                    {post.title}
                  </Link>
                );
              })}
              <h5 className="mt-16 mb-4 text-2xl font-medium">
                Fleet Operations
              </h5>
              {FleetDoc.map((post) => {
                return (
                  <Link
                    to={post.uri}
                    className="block my-2 text-primary hover:text-primary-light"
                  >
                    {post.title}
                  </Link>
                );
              })}
              <h5 className="mt-16 mb-4 text-2xl font-medium">
                Policies and Violations
              </h5>
              {PoliciesDoc.map((post) => {
                return (
                  <Link
                    to={post.uri}
                    className="block my-2 text-primary hover:text-primary-light"
                  >
                    {post.title}
                  </Link>
                );
              })}
              <h5 className="mt-16 mb-4 text-2xl font-medium">
                Safety and Security Tips
              </h5>
              {SafetyDoc.map((post) => {
                return (
                  <Link
                    to={post.uri}
                    className="block my-2 text-primary hover:text-primary-light"
                  >
                    {post.title}
                  </Link>
                );
              })}
            </div>
            <div className="col-span-2 md:col-span-1">
              <h5 className="mb-4 text-2xl font-medium">
                Fundamental of KATSANA
              </h5>
              {FundamentalDoc.map((post) => {
                return (
                  <Link
                    to={post.uri}
                    className="block my-2 text-primary hover:text-primary-light"
                  >
                    {post.title}
                  </Link>
                );
              })}
              <h5 className="mt-16 mb-4 text-2xl font-medium">Live Tracking</h5>
              {TrackingDoc.map((post) => {
                return (
                  <Link
                    to={post.uri}
                    className="block my-2 text-primary hover:text-primary-light"
                  >
                    {post.title}
                  </Link>
                );
              })}
              <h5 className="mt-16 mb-4 text-2xl font-medium">
                Print and Export Reports
              </h5>
              {ReportDoc.map((post) => {
                return (
                  <Link
                    to={post.uri}
                    className="block my-2 text-primary hover:text-primary-light"
                  >
                    {post.title}
                  </Link>
                );
              })}
              <h5 className="mt-16 mb-4 text-2xl font-medium">
                Tips and Trick
              </h5>
              {TipsDoc.map((post) => {
                return (
                  <Link
                    to={post.uri}
                    className="block my-2 text-primary hover:text-primary-light"
                  >
                    {post.title}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Cta />
    </Layout>
  );
};

export default DocIndex;

export const pageQuery = graphql`
  query DocArchive {
    # Account Information
    AccountInformationDoc: allWpDoc(
      filter: {
        categories: {
          nodes: { elemMatch: { name: { eq: "Account Information" } } }
        }
      }
    ) {
      nodes {
        uri
        title
      }
    }
    # Fleet Operations
    FleetDoc: allWpDoc(
      filter: {
        categories: {
          nodes: { elemMatch: { name: { eq: "Fleet Operations" } } }
        }
      }
    ) {
      nodes {
        uri
        title
      }
    }
    # Policies & Violations
    PoliciesDoc: allWpDoc(
      filter: {
        categories: {
          nodes: { elemMatch: { name: { eq: "Policies & Violations" } } }
        }
      }
    ) {
      nodes {
        uri
        title
      }
    }
    # Safety and Security Tips
    SafetyDoc: allWpDoc(
      filter: {
        categories: {
          nodes: { elemMatch: { name: { eq: "Safety and Security tips" } } }
        }
      }
    ) {
      nodes {
        uri
        title
      }
    }
    # Fundamental of KATSANA
    FundamentalDoc: allWpDoc(
      filter: {
        categories: {
          nodes: { elemMatch: { name: { eq: "Fundamentals of KATSANA" } } }
        }
      }
    ) {
      nodes {
        uri
        title
      }
    }
    # Live Tracking
    TrackingDoc: allWpDoc(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Live Tracking" } } } }
      }
    ) {
      nodes {
        uri
        title
      }
    }
    # Print and Export Reports
    ReportDoc: allWpDoc(
      filter: {
        categories: {
          nodes: { elemMatch: { name: { eq: "Print and Export Reports" } } }
        }
      }
    ) {
      nodes {
        uri
        title
      }
    }
    # Tips and Trick
    TipsDoc: allWpDoc(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Tips & Tricks" } } } }
      }
    ) {
      nodes {
        uri
        title
      }
    }
  }
`;
